<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="customer-order"
        :canDelete="!isHome"
        :canEdit="!isHome"
        :changeToggle="changeToggle"
        :dependantBlades="[
            { bladeName: 'stock-consignment' },
            { bladeName: 'customer-consignment' }]"
        @edit="newOrder"
        :hideBackButton="isHome"
        :loadingMsg="loadingMsg"
        navigation="customer-orders"
        :onFinalize="useFastProcessing ? orderSaved : null"
        :onGetSave="getOrderToSave"
        :onPullSuccessAsync="orderPulled"
        :onNew="newOrder"
        :saveToggle="saveToggle"
        title="Customer Order"
        :defaultBladeWidth="650">
        <template v-slot:notFound="ite">
            <BT-Select-List-Box
                @change="ite.refresh"
                v-model="mAgreementID"
                label="Customer"
                itemText="buyer.companyName"
                itemValue="id"
                isSelecting
                height="400"
                isEditing
                navigation="customers"
                :onCanSelect="c => !c.isSuspended"
                :params="{ properties: 'ID,Buyer' }"
                :searchProperties="['buyer.companyName']">
                <template v-slot="{ item }">
                    <v-list-item-avatar>
                        <v-icon :color="item.isSuspended ? 'error' : 'primary'">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle :class="item.isSuspended ? 'error--text' : null">
                            {{ item.buyer.companyName }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span v-if="item.isSuspended" class="error--text">Suspended</span>
                    </v-list-item-action>
                </template>
            </BT-Select-List-Box>
        </template>
        
        <template v-slot="{ item, data }">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-list-item v-if="item != null && item.buyer != null || currentPolicy != null" dense>
                        <v-list-item-avatar>
                            <v-img :src="companyLogoURL(item.buyerID || currentPolicy.buyerID)" class="mx-auto">
                                <template v-slot:placeholder>
                                    <v-icon color="primary">mdi-account</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <span v-if="item.buyer != null">{{ item.buyer.companyName }}</span>
                                <span v-else>{{ currentPolicy.buyerName }}</span>
                                <BT-Btn
                                    bladeName="customer"
                                    buttonClass="primary--text"
                                    icon="mdi-open-in-new"
                                    :id="item.supplyAgreementID || currentPolicy.id"
                                    inline
                                    navigation="customers"
                                    small
                                    title="Open customer settings in new tab." />
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu
                                    v-if="!data.isNew"
                                    :getOnOpenAsync="getProgress"
                                    hideHeader
                                    icon="mdi-progress-question"
                                    :item="item"
                                    left
                                    title="Status">
                                    <template v-slot="{ item }">
                                        <v-list-item v-for="(progress, ind) in item" :key="ind">
                                            <v-list-item-avatar>
                                                <v-icon :class="progress.isTrue ? 'success--text' : 'error--text'">mdi-progress-check</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ progress.title }}</v-list-item-title>
                                                <v-list-item-subtitle v-if="progress.dateOn != null">{{ progress.dateOn | toLongDateAndTime }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>{{ progress.subtitle }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="progress.location != null">{{ progress.location | toLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </BT-Menu>
                                <BT-Menu
                                    v-if="item != null && item.locationID != null"
                                    icon="mdi-note-text"
                                    title="Standing notes for this location">
                                    <template #default>

                                        <BT-Entity
                                            @posted="() => refreshNote(item.locationID)"
                                            :findItem="item => { if (item != null && item.length > 0) { return item[0] } else { return null } }"
                                            ignoreID
                                            :itemValue="item.locationID"
                                            navigation="location-notes"
                                            :onNew="() => { return { locationID: item.locationID, msg: null } }"
                                            :params="{ locationID: item.locationID }"
                                            :single="false">
                                            <template v-slot="{ item, save, isChanged }">
                                                <v-textarea
                                                    class="ma-3"
                                                    auto-grow
                                                    hide-details
                                                    placeholder="Create instructions for this location."
                                                    rows="6"
                                                    v-model="item.msg">
                                                    <template #append>
                                                        <v-slide-x-transition>
                                                        <v-btn v-if="isChanged" icon @click="save" class="ma-0 mx-1" small>
                                                            <v-icon>mdi-content-save</v-icon>
                                                        </v-btn>
                                                        </v-slide-x-transition>
                                                    </template>
                                                    </v-textarea>
                                                </template>
                                            </BT-Entity>
                                    </template>
                                </BT-Menu>
                                <BT-Menu
                                    hideHeader
                                    icon="mdi-cog"
                                    left
                                    title="More Options">
                                    <template v-slot>
                                        <v-list-item v-if="(data.isNew || data.isEditing) && csvContains(item.tagsCSV, 'IgnoreDeliveryFee')" 
                                            @click="toggleDeliveryFee(item)"
                                            dense>
                                            <v-list-item-icon>
                                                <v-icon class="success--text">mdi-plus</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Enable Delivery Fees</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-else-if="data.isNew || data.isEditing"
                                            @click="toggleDeliveryFee(item)"
                                            dense>
                                            <v-list-item-icon>
                                                <v-icon class="error--text">mdi-close</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Disable Delivery Fees</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                        <v-list-item v-if="!data.isNew" dense @click="emailAdjustment(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Send As Email</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <BT-Print
                                            v-if="item != null && !data.isNew"
                                            buttonClass=""
                                            :getFileName="item => `Purchase Order #${item.customerOrderNumber}.pdf`"
                                            :item="item"
                                            :itemID="item.id"
                                            :onPullSuccessAsync="pullForInvoice">
                                            <template v-slot:activator="{ openDialog }">
                                                <v-list-item @click="openDialog" dense>
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-printer</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Print</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot="invD">
                                                <v-container v-if="invD != null" class="pdf-page">
                                                    <v-row>
                                                        <v-col cols="8">
                                                            <div style="height: 25px;" />
                                                            <v-img v-if="invD.strategy != 'jspdf'" class="pdf-logo" :src="invD.item.logoImgData" max-width="100px" max-height="100px" />
                                                            <v-list-item three-line>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                                                    <v-list-item-title>
                                                                        <v-icon left small>mdi-account</v-icon><strong>{{ $BlitzIt.auth.session.company.accountName }}</strong>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null" >
                                                                        <v-icon left small>mdi-email</v-icon>{{ $BlitzIt.auth.session.company.primaryEmail }}
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                                                        <v-icon left small>mdi-phone</v-icon>{{ $BlitzIt.auth.session.company.phoneNumber }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item class="mt-5">
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>
                                                                        <v-icon left small>mdi-arrow-right-bottom</v-icon>BILLED TO
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title>
                                                                        <v-icon left small>mdi-account</v-icon><strong>{{ item.buyer.companyName }}</strong>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <v-icon left small>mdi-map-marker</v-icon>{{ item.location | toLocationLineOne }}
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle>
                                                                        <v-icon left small>mdi-map-marker</v-icon>{{ item.location | toLocationLineTwo }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <h2>Purchase Order</h2>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item dense>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                                    <v-list-item-title>{{ invD.item.customerOrderNumber }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item dense>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                                    <v-list-item-title>{{ invD.item.purchaseOrderNumber }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item dense>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                                                    <v-list-item-title>{{ invD.item.issuedOn | toShortDate }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item dense>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                                                    <v-list-item-title>{{ invD.item.dueOn | toShortDate }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                                        <v-col cols="2">QTY</v-col>
                                                        <v-col cols="6">DESCRIPTION</v-col>
                                                        <v-col cols="2">UNIT PRICE</v-col>
                                                        <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                                                    </v-row>

                                                    <v-row no-gutters v-for="(lineItem, index) in invD.item.lineItems" :key="index" style="font-size: 13px;">
                                                        <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                                        <v-col cols="6">{{ lineItem.description }}</v-col>
                                                        <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                                        <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                                                    </v-row>
                                                    
                                                    <v-divider style="margin: 10px 0px;" />

                                                    <v-row dense style="font-size: 14px;">
                                                        <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                                        <v-col cols="3" style="text-align: right;">{{ invD.item.subTotal | toCurrency }}</v-col>
                                                    </v-row>
                                                    <v-row dense style="font-size: 14px;">
                                                        <v-col cols="9" style="text-align: right;">GST:</v-col>
                                                        <v-col cols="3" style="text-align: right;">{{ invD.item.taxTotal | toCurrency }}</v-col>
                                                    </v-row>
                                                    
                                                    <v-divider style="margin: 10px 0px;" />
                                                    
                                                    <v-row>
                                                        <v-col cols="9" style="text-align: right;">Total:</v-col>
                                                        <v-col cols="3" style="text-align: right;">
                                                            <h4>{{ invD.item.amountTotal | toCurrency }}</h4>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <!-- <v-container v-if="invD != null && invD.item != null" style="font-family: sans-serif;">
                                                    <v-row style="background-color: #1f5a7c; color: white; padding: 5px;">
                                                        <v-col cols="12">
                                                            <v-row>
                                                                <h2>{{ item.seller.companyName }}</h2>
                                                                <v-spacer />
                                                                <h3>Invoice</h3>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="7">
                                                            <h4>Biller</h4>
                                                            <h5 v-if="$BlitzIt.auth.session.company.accountName != null">{{ $BlitzIt.auth.session.company.accountName }}</h5>
                                                            <h5 v-if="$BlitzIt.auth.session.company.primaryEmail != null">Email: {{ $BlitzIt.auth.session.company.primaryEmail }}</h5>
                                                            <h5 v-if="$BlitzIt.auth.session.company.phoneNumber != null">Phone: {{ $BlitzIt.auth.session.company.phoneNumber }}</h5>
                                                            <h4 style="margin-top: 80px;">Bill To</h4>
                                                            <h4>{{ item.buyer.companyName }}</h4>
                                                        </v-col>
                                                        <v-col cols="5">
                                                            <v-list dense>
                                                                <v-list-item dense>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                                        <v-list-item-title>{{ item.customerOrderNumber }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item dense>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                                        <v-list-item-title>{{ item.purchaseOrderNumber }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item dense>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                                                        <v-list-item-title>{{ invD.item.issuedOn | toShortDate }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item dense>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                                                        <v-list-item-title>{{ invD.item.dueOn | toShortDate }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row dense style="background-color: #1f5a7c; color: white; padding: 0px 5px;">
                                                        <v-col cols="2">Qty</v-col>
                                                        <v-col cols="6">Description</v-col>
                                                        <v-col cols="2">Unit Price</v-col>
                                                        <v-col cols="2" style="text-align: right;">Total</v-col>
                                                    </v-row>

                                                    <v-row dense v-for="(lineItem, index) in invD.item.lineItems" :key="index"
                                                        style="font-size: 12px;">
                                                        <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                                        <v-col cols="6">{{ lineItem.description }}</v-col>
                                                        <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                                        <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                                                    </v-row>
                                                    
                                                    <v-divider style="margin: 10px 0px;" />

                                                    <v-row dense style="font-size: 14px;">
                                                        <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                                        <v-col cols="3" style="text-align: right;">{{ invD.item.subTotal | toCurrency }}</v-col>
                                                    </v-row>
                                                    <v-row dense style="font-size: 14px;">
                                                        <v-col cols="9" style="text-align: right;">GST:</v-col>
                                                        <v-col cols="3" style="text-align: right;">{{ invD.item.taxTotal | toCurrency }}</v-col>
                                                    </v-row>
                                                    
                                                    <v-divider style="margin: 10px 0px;" />
                                                    
                                                    <v-row>
                                                        <v-col cols="9" style="text-align: right;">Total:</v-col>
                                                        <v-col cols="3" style="text-align: right;">
                                                            <h4>{{ invD.item.amountTotal | toCurrency }}</h4>
                                                        </v-col>
                                                    </v-row>
                                                </v-container> -->
                                            </template>
                                        </BT-Print>
                                        <v-divider />
                                        <BT-Dialog v-if="!data.isNew" width="300">
                                            <template v-slot:activator="{ openDialog }">
                                                <v-list-item @click="openDialog" dense>
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-history</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>See User Activity</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template>
                                                <BT-List
                                                    navigation="ordering-user-activities"
                                                    :params="{ searchString: item.customerOrderNumber }"
                                                    refreshAlways>
                                                    <template v-slot:listItem="{ item }">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <span>
                                                                    <BT-Entity 
                                                                        alternateText="system" 
                                                                        inline 
                                                                        navigation="users" 
                                                                        itemText="userName" 
                                                                        :itemValue="item.userID" />
                                                                </span>{{ item.notes }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>{{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </BT-List>
                                            </template>
                                        </BT-Dialog>
                                        <v-list-item v-if="!useFastProcessing" dense @click="showMore = !showMore">
                                            <v-list-item-icon>
                                                <v-icon>{{ showMore ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ showMore ? 'Hide Options' : 'Show More Options' }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </BT-Menu>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <!-- <v-list-item v-else-if="currentPolicy != null" dense>
                        <v-list-item-avatar>
                            <v-img :src="companyLogoURL(currentPolicy.buyerID )" class="mx-auto">
                                <template v-slot:placeholder>
                                    <v-icon color="primary" >mdi-account</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ currentPolicy.buyerName }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="!data.isNew">
                            <v-row dense>
                                <BT-Menu
                                    :getOnOpenAsync="getProgress"
                                    hideHeader
                                    icon="mdi-progress-question"
                                    :item="item"
                                    title="Status">
                                    <template v-slot="{ item }">
                                        <v-list-item v-for="(progress, ind) in item" :key="ind">
                                            <v-list-item-avatar>
                                                <v-icon :class="progress.isTrue ? 'success--text' : 'error--text'">mdi-progress-check</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ progress.title }}</v-list-item-title>
                                                <v-list-item-subtitle v-if="progress.dateOn != null">{{ progress.dateOn | toLongDateAndTime }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>{{ progress.subtitle }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="progress.location != null">{{ progress.location | toLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </BT-Menu>
                                <BT-Btn
                                    bladeName="customer"
                                    navigation="customers"
                                    icon="mdi-cog"
                                    :id="item.supplyAgreementID"
                                    title="View Customer Settings" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item> -->
                </v-col>

                <v-col v-if="!data.isNew" cols="6" md="4">
                    <BT-Field-String
                        v-model="item.purchaseOrderNumber"
                        :isEditing="data.isEditing"
                        label="Purchase Order #" />
                </v-col>
                <v-col v-if="!data.isNew" cols="6" md="4">
                    <BT-Field-String
                        v-model="item.customerOrderNumber"
                        :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched)"
                        label="Customer Order #" />
                </v-col>
                <v-col v-if="!data.isNew && !data.isEditing" cols="6" md="4">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On" />
                </v-col>
                <v-col v-if="!useFastProcessing && (data.isNew || data.isEditing)" cols="12">
                    <BT-Select-List-Box
                        v-if="(data.isEditing || data.isNew) && currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted' && isRestrictedOrdering"
                        v-model="item.dueDate"
                        :canSearch="false"
                        height="400"
                        :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched) || data.isNew"
                        :isSelecting="item.dueDate == null"
                        :items="currentPolicy.orderSlots"
                        itemText="deliverBy"
                        itemValue="deliverBy"
                        label="Delivery Slot"
                        :onCanSelect="v => { return v != null && v.purchaseOrderID == null }"
                        small
                        textFilter="toDayShortDate"
                        width="200">
                        <template v-slot:actionsRight>
                            <v-btn small icon @click="toggleRestrictedOrdering(item, data)">
                                <v-icon small>{{ !isRestrictedOrdering ? 'mdi-calendar-lock' : 'mdi-calendar-lock-open' }}</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.deliverBy | toDayShortDate }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.orderBy != null" class="grey--text">
                                    {{ item.orderBy | toTimeSpan }} till cut-off time
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.purchaseOrderID != null">
                                <v-list-item-action-text>
                                    <BT-Entity
                                        itemText="customerOrderNumber"
                                        :itemValue="item.purchaseOrderID"
                                        navigation="customer-orders"
                                        single>
                                        <template v-slot:prepend>#</template>
                                        <template v-slot:append>
                                            <v-btn small icon target="_blank" :to="{ name: 'customer-order', params: { id: item.purchaseOrderID }}" title="Open in new tab">
                                                <v-icon small>mdi-open-in-new</v-icon>
                                            </v-btn>
                                        </template>
                                    </BT-Entity>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </template>
                    </BT-Select-List-Box>
                
                    <BT-Field-Date
                        v-else-if="data.isNew && currentPolicy != null"
                        :dateRules="dateRules"
                        isEditing
                        v-model="item.dueDate"
                        label="Delivery Date"
                        :dateFrom="dateFrom"
                        :showActions="data.isEditing || data.isNew">
                        <template v-slot:actions>
                            <v-btn small icon @click="toggleRestrictedOrdering(item, data)">
                                <v-icon small>{{ !isRestrictedOrdering ? 'mdi-calendar-lock' : 'mdi-calendar-lock-open' }}</v-icon>
                            </v-btn>
                        </template>
                    </BT-Field-Date>
                    <BT-Field-Date
                        v-else
                        v-model="item.dueDate"
                        label="Due On"
                        :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched)"
                        :showActions="data.isEditing || data.isNew">
                        <template v-slot:actions>
                            <v-btn small icon @click="toggleRestrictedOrdering(item, data)">
                                <v-icon small>{{ !isRestrictedOrdering ? 'mdi-calendar-lock' : 'mdi-calendar-lock-open' }}</v-icon>
                            </v-btn>
                        </template>
                    </BT-Field-Date>
                </v-col>
                <v-col cols="12" :sm="data.isNew ? '12' : '6'">
                    <BT-Field-Address
                        v-if="!data.isEditing && !data.isNew || currentPolicy == null"
                        label="TO"
                        :value="item.location" />
                    <BT-Select-List-Box
                        v-else
                        v-model="item.locationID"
                        isEditing
                        :isSelecting="item.locationID == null"
                        :items="possibleLocations"
                        itemValue="id"
                        label="Destination"
                        textFilter="toLocationLine">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                <v-list-item-subtitle class="grey--text">{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Select-List-Box>
                </v-col>
                <v-col v-if="!data.isNew" cols="12" sm="6">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Creator</v-list-item-subtitle>
                            <v-list-item-title>{{ item.createdByEntity }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            
            <v-slide-y-transition>
                <div v-if="showMore">
                    <BT-Field-String
                        v-if="data.isNew"
                        v-model="item.purchaseOrderNumber"
                        label="Purchase Order #"
                        horizontal
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Select
                        v-if="data.isNew"
                        v-model="item.fulfillerAgreementID"
                        label="Fulfill By"
                        isEditing
                        itemValue="id"
                        itemText="seller.companyName"
                        navigation="couriers-fulfilling"
                        :params="{ filterBy: ''}" />

                    <BT-Field-Select
                        v-if="data.isNew || item.fulfillerID == item.sellerID"
                        v-model="item.requestedDepartureLocationID"
                        label="Supply From"
                        itemValue="id"
                        itemText="locationName"
                        :isEditing="data.isEditing && item.fulfillerID == item.sellerID || data.isNew"
                        navigation="locations" />

                    <v-row v-if="!useFastProcessing" no-gutters class="mx-1">
                        <v-col cols="6">
                            <BT-Square-Check
                                falseClass="success--text"
                                falseIcon="mdi-factory"
                                :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched) || data.isNew"
                                :label="item.isBuyerCourier ? 'Customer To Organize Courier' : 'I Will Organize Courier'"
                                outlined
                                trueIcon="mdi-account"
                                v-model="item.isBuyerCourier" />
                        </v-col>
                        <v-col cols="6">
                            <BT-Square-Check
                                falseClass="success--text"
                                falseIcon="mdi-truck"
                                :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched) || data.isNew"
                                :label="item.isRequestPickup ? 'Pickup' : 'Deliver'"
                                outlined
                                trueIcon="mdi-clipboard-arrow-up"
                                v-model="item.isRequestPickup" />
                        </v-col>
                    </v-row>
                </div>
            </v-slide-y-transition>

            <!-- <v-btn v-if="!useFastProcessing" block text @click="showMore = !showMore">
                {{ showMore ? 'Hide Options' : 'More Options' }}
            </v-btn> -->

            <BT-Field-String
                v-if="useFastProcessing"
                v-model="item.purchaseOrderNumber"
                label="Purchase Order #"
                horizontal
                :isEditing="data.isEditing || data.isNew" />

            <!-- <v-row v-if="!useFastProcessing" class="mx-1">
                <v-col cols="6">
                    <BT-Square-Check
                        falseClass="success--text"
                        falseIcon="mdi-factory"
                        :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched) || data.isNew"
                        :label="item.isBuyerCourier ? 'Customer To Organize Courier' : 'I Will Organize Courier'"
                        outlined
                        trueIcon="mdi-account"
                        v-model="item.isBuyerCourier" />
                </v-col>
                <v-col :cols="6">
                    <BT-Square-Check
                        falseClass="success--text"
                        falseIcon="mdi-truck"
                        :isEditing="data.isEditing && (item.fulfillerID == item.sellerID || !item.isDispatched) || data.isNew"
                        :label="item.isRequestPickup ? 'Pickup' : 'Deliver'"
                        outlined
                        trueIcon="mdi-clipboard-arrow-up"
                        v-model="item.isRequestPickup" />
                </v-col>
            </v-row> -->

            <BT-Btn-Row v-if="!data.isNew && !data.isEditing">
                <!-- <template v-slot:left>
                    <BT-Dialog icon="mdi-history" width="300">
                        <template v-slot:activator="{ openDialog }">
                            <BT-Btn
                                v-if="!data.isNew"
                                icon="mdi-history"
                                label="Activity"
                                @click="openDialog" />
                        </template>
                        <template>
                            <BT-List
                                navigation="ordering-user-activities"
                                :params="{ searchString: item.customerOrderNumber }"
                                refreshAlways>
                                <template v-slot:listItem="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span>
                                                <BT-Entity 
                                                    alternateText="system" 
                                                    inline 
                                                    navigation="users" 
                                                    itemText="userName" 
                                                    :itemValue="item.userID" />
                                            </span>{{ item.notes }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </BT-List>
                        </template>
                    </BT-Dialog>
                </template> -->
                <template v-slot:right>
                    <BT-Btn
                        v-if="item.isConfirmed == null"
                        buttonClass="error--text"
                        icon="mdi-close"
                        label="Reject"
                        @click="approvePurchaseOrder(item, false)" />

                    <BT-Btn
                        v-if="item.isConfirmed == null"
                        buttonClass="success--text"
                        icon="mdi-check"
                        label="Approve"
                        @click="approvePurchaseOrder(item, true)" />

                    <BT-Btn
                        v-if="item.fulfillerID == item.sellerID && item.isDispatched"
                        bladeName="stock-consignment"
                        icon="mdi-clipboard-outline"
                        :id="item.id"
                        label="Consignment"
                        nav />

                    <BT-Btn
                        v-if="item.isConfirmed == true && !item.isDispatched"
                        @click="invoicePurchaseOrder(item)"
                        confirmText="This will turn the order into an invoice.  Are you sure?"
                        icon="mdi-receipt"
                        label="Invoice"
                        title="Convert the order straight into an invoice without going through stock and delivery" />
                    
                    <BT-Btn
                        v-if="item.isConfirmed == true && item.fulfillerID == null"
                        @click="selectFulfiller(item, true)"
                        icon="mdi-account"
                        label="Self Fulfill"
                        title="My company will fulfill this order" />

                    <BT-Btn
                        v-if="item.isConfirmed == true && item.fulfillerID == null"
                        @click="selectFulfiller(item, false)"
                        icon="mdi-arrow-decision"
                        label="Fulfill Via"
                        title="Select a company to fulfill this order" />
                        
                    <BT-Btn
                        v-if="item.fulfillerID != null && !item.isDispatched"
                        @click="unselectFulfiller(item)"
                        icon="mdi-close"
                        :label="item.fulfiller.companyName"
                        title="Cancel request for this company to fulfill the order" />

                    <BT-Btn
                        v-if="item.fulfillerID == item.sellerID && !item.isDispatched"
                        @click="processPurchaseOrder(item)"
                        icon="mdi-arrow-right-circle"
                        label="Consign"
                        title="Proceed to next step of purchase order - a stock consignment" />

                </template>
            </BT-Btn-Row>
            
            <BT-Table
                :canRefresh="false"
                :canSelect="false"
                dense
                :headers="[
                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                    { text: 'Description', value: 'description', display: true, subtitle: 1 },
                    { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency' },
                    { text: 'Total', value: 'total', display: true, align: 'end' }]"
                hideActions
                :items="item.orderItems">
                <template v-slot:noItems>
                    <BT-Btn
                        block
                        @click="mDrawer = !mDrawer"
                        icon="mdi-cube-outline"
                        label="Select Products" />
                </template>
                <template v-slot:description="{ item }">
                    <v-edit-dialog>
                        <v-icon v-if="item.placeholderProductID != null" left small>mdi-alert-circle-outline</v-icon>{{ item.description }}
                        <template v-slot:input>
                            <BT-Field-Select
                                v-model="item.placeholderProductID"
                                navigation="products"
                                itemText="productName"
                                label="Billable Product"
                                :isEditing="data.isEditing || data.isNew" />
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:total="{ item }">
                    {{ (item.quantity * item.unitPrice) | toCurrency }}
                </template>
                <template v-slot:actions>
                    <v-btn
                        v-if="(data.isEditing || data.isNew)"
                        icon
                        @click.stop="mDrawer = !mDrawer">
                        <v-icon>mdi-table-column-plus-before</v-icon>
                    </v-btn>
                    <BT-Order-Items-Dialog
                        v-if="(data.isEditing || data.isNew) && !useFastProcessing"
                        :block="false"
                        buttonClass=""
                        icon="mdi-pencil"
                        :order="item"
                        label="Order Items"
                        :policy="currentPolicy"
                        :text="null"
                        @ok="x => { updateOrder(x, false); }" />
                </template>
            </BT-Table>

            <v-divider class="my-1" />

            <div v-if="isLengthyArray(item.orderItems)" class="mb-2">
                <v-row no-gutters>
                    <div v-if="data.isEditing || data.isNew">
                        
                    </div>
                    <v-spacer />
                    <div>
                        <p class="text-right ma-3 mt-0">Subtotal: {{ item.subTotal | toCurrency }}</p>
                        <p class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</p>
                    </div>
                </v-row>
                
                
                <v-divider />
                <v-row class="ma-0 pa-0 mx-3">
                    
                    <!-- <BT-Menu
                        hideHeader
                        icon="mdi-email"
                        text="Email">
                        <template v-slot>
                            <v-list-item @click="emailAdjustment(item)">
                                <v-list-item-content>
                                    <v-list-item-title>Send Adjustment Notification</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu> -->
                    
                    <v-spacer />

                    <div>
                        <!-- <v-divider /> -->
                        <h3 class="text-right mt-2">Total: {{ item.amountTotal | toCurrency }}</h3>
                    </div>
                </v-row>
            </div>

            <BT-Snack v-model="msg" />
        </template>
        <template v-slot:save="{ save, isChanged, showSave, hideSaveAndClose, item }">
            <v-slide-x-transition group>
                <v-btn v-if="isChanged && useFastProcessing && isLengthyArray(item.orderItems)" class="mx-1" color="primary" @click="save(false)" key="aa"><v-icon left>mdi-content-save</v-icon>Save And Next</v-btn>
                <v-btn v-if="isChanged && !useFastProcessing && showSave" class="mx-1" color="primary" @click="save(true)" key="a"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
                <v-btn v-if="isChanged && !useFastProcessing && showSave && !hideSaveAndClose" class="mx-1" color="primary" @click="save(false)" key="b"><v-icon left>mdi-content-save</v-icon>Save And Close</v-btn>
            </v-slide-x-transition> 
        </template>
        <template v-slot:page="{ item, data, bladeHeight }">
            <BT-Order-Items-Drawer
                v-if="data.isEditing || data.isNew"
                :drawerToggle="mDrawer"
                :height="bladeHeight"
                :isEditing="data.isEditing || data.isNew"
                :order="item"
                label="Order Items"
                :policy="currentPolicy"
                @ok="x => { updateOrder(x, false); }"
                @okAndSave="x => { updateOrder(x, true); }" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { getImageData, recalculateOrder } from '~helpers';

export default {
    name: 'Customer-Order-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTOrderItemsDialog: () => import('~components/BT-Order-Items-Dialog.vue'),
        BTOrderItemsDrawer: () => import('~components/BT-Order-Items-Drawer.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            changeToggle: false,
            currentOrder: null,
            currentPolicy: null,
            dueDateOptions: [],
            dateFrom: null,  
            dateRules: null,
            isRestrictedOrdering: true,
            loadingMsg: null,
            locationID: null,
            mAgreementID: null,
            mDrawer: false,
            msg: null,
            panelV: 0,
            saveToggle: false,
            showMore: false,
            showToggle: false,
            useFastProcessing: false,
        }
    },
    props: {
        bladesData: null,
        isHome: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        possibleLocations() {
            return this.currentPolicy != null ? this.currentPolicy.possibleLocations : [];
        }
    },
    async mounted() {
        if (this.$route.query.id == 'new' || this.$route.params.id == 'new') {
            //is new
            if (this.$route.params?.mode == 'instant') {
                this.useFastProcessing = true;
                
                if (this.locationID == null) {
                    this.locationID = await this.$selectItem({
                        navigation: 'locations',
                        itemText: 'locationName',
                        itemValue: 'id',
                        required: true
                    });
                }
            }

            if (this.$route.query.agreementID != null) {
                this.mAgreementID = this.$route.query.agreementID;
            }
            else {
                this.showToggle = !this.showToggle;
                this.mAgreementID = '1';
            }
        }
        else {
            this.mAgreementID = '1';
        }
    },
    methods: {
        async approvePurchaseOrder(po, approval) {
            this.loadingMsg = 'Approving';

            try {
                await this.$BlitzIt.api.patch('customer-order-approval', { id: po.id, isConfirmed: approval, rowVersion: po.rowVersion });
                po.isConfirmed = approval;
                this.changeToggle = !this.changeToggle;
                //po.rowVersion = res.data.data.rowVersion;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async emailAdjustment(po) {
            try {
                this.loadingMsg = "Sending";
                await this.$BlitzIt.api.post('customer-orders', null, null, '/post/SendEmail/Adjustment/' + po.id);
            }
            catch (err) {
                this.console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async refreshNote(locationID) {
            await this.$BlitzIt.store.getAll('location-notes', { locationID: locationID }, true, null, null, false);
        },
        async getProgress(order) {
            this.loadingMsg = 'Getting Progress';
            var progressList = []; //{ location, dateOn, title, subtitle }
            
            progressList.push({
                dateOn: order.createdOn,
                isTrue: order.createdOn != null,
                location: null,
                subtitle: null,
                title: 'Created',
            });

            progressList.push({
                dateOn: order.confirmedOn,
                isTrue: order.confirmedOn != null,
                location: null,
                subtitle: null,
                title: 'Confirmed',
            });

            progressList.push({
                dateOn: null,
                isTrue: order.isDispatched,
                location: null,
                subtitle: order.isDispatched ? 'Done' : 'Pending',
                title: 'Processed',
            });
            
            if (order.isDispatched) {
                //search for courier consignment
                try {
                    var releaseRes = await this.$BlitzIt.api.get('customer-consignments', '/Get/Progress/' + order.id);
                    if (releaseRes != null && this.isLengthyArray(releaseRes.data.data)) {
                        releaseRes.data.data.forEach(progress => {
                            var e = {
                                dateOn: progress.releasedOn || progress.releaseDueOn,
                                isTrue: progress.releasedOn != null,
                                location: progress.location,
                                subtitle: null,
                                title: 'Released'
                            };

                            if (progress.releasedOn != null && progress.deliveredOn == null) {
                                e.title = 'On The Road!';
                            }

                            progressList.push(e);

                            progressList.push({
                                dateOn: progress.deliveredOn || progress.deliveryDueOn,
                                isTrue: progress.deliveredOn != null,
                                location: progress.location,
                                subtitle: null,
                                title: 'Delivered'
                            });
                        });
                    }
                }
                catch {
                    console.log('finding progress failed');
                }
            }

            if (progressList.length == 3) {
                progressList.push({
                    dateOn: null,
                    isTrue: false,
                    location: null,
                    subtitle: '...',
                    title: 'Planning Courier'
                })
            }

            this.loadingMsg = null;
            return progressList;
        },
        async unselectFulfiller(po) {
            this.loadingMsg = 'Cancelling';
            
            try {
                var patchData = {
                    id: po.id,
                    rowVersion: po.rowVersion
                }

                var res = await this.$BlitzIt.api.patch('customer-order-fulfilling', patchData);
                
                po.fulfillerID = null;
                po.fulfiller = null;
                po.rowVersion = res.data.data.rowVersion;
                this.changeToggle = !this.changeToggle;
            }
            // catch (err) {
            //     po.errorMsg = this.extractErrorDescription(err);
            // }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectFulfiller(po, isSelf = false) {
            this.loadingMsg = 'Finding a company to fulfill this order';
            
            try {
                var patchData = {
                    id: po.id,
                    isSelf: isSelf,
                    rowVersion: po.rowVersion
                }

                var sup = null;
                if (!isSelf) {
                    sup = await this.$selectItem({
                        navigation: 'couriers-fulfilling',
                        itemText: 'seller.companyName'
                    });

                    if (sup == null) {
                        return;
                    }

                    patchData.fulfillerAgreementID = sup.id;
                }
                
                var res = await this.$BlitzIt.api.patch('customer-order-fulfilling', patchData);
                
                if (isSelf) {
                    po.fulfillerID = po.sellerID;
                    po.fulfiller = po.seller;
                }
                else {
                    po.fulfillerID = sup.sellerID;
                    po.fulfiller = sup.seller;
                }
                
                po.rowVersion = res.data.data.rowVersion;
                this.changeToggle = !this.changeToggle;
            }
            // catch (err) {
            //     po.errorMsg = this.extractErrorDescription(err);
            // }
            finally {
                this.loadingMsg = null;
            }
        },
        async invoicePurchaseOrder(po) {
            this.loadingMsg = 'Invoicing';

            var data = { id: po.id, performedOn: null, useOriginalOrderItems: true, rowVersion: po.rowVersion, orderItems: [] }
            try {
                await this.$BlitzIt.api.patch('customer-order-invoicing', data);
                po.isFulfilled = true;

                this.$BlitzIt.store.deleteLocal('customer-orders', po.id);

                if (this.bladesData != null) {
                    this.bladesData.closeBlade({ bladeName: 'customer-order' });
                }
                else {
                    //try navigate there
                    await this.twiddleThumbs()
                    this.$router.push({ name: 'customer-invoice', params: { id: po.id, navIgnore: true }});
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        getOrderToSave(bladeData, order) {
            if (order.id == null) {
                //is new so remove all auto order items
                order.orderItems = order.orderItems.filter(x => x.productID != null);
            }
            else {
                //is editing existing so keep all order items (?)
            }
            return order;
        },
        async newOrder(bladeData, order) {
            //get policy
            try {
                this.loadingMsg = 'Preparing';

                var agrID = null;
                
                if (order != null && order.supplyAgreementID != null) {
                    agrID = order.supplyAgreementID;
                }
                else if (bladeData != null && bladeData.data != null && bladeData.data.agreementID != null) {
                    agrID = bladeData.data.agreementID;
                }
                else if (this.mAgreementID != null) {
                    agrID = this.mAgreementID;
                }

                if (agrID == '1' || agrID == null) {
                    isNew = true;
                    return null;
                }

                var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/get/Policy');
                var policy = resData.data.data;
                var isNew = false;

                if (order == null) {
                    isNew = true;
                    order = {
                        isBuyerCourier: policy.isRequirePickup ? true : policy.isPreferPickup,
                        locationID: policy.defaultLocationID,
                        location: policy.defaultLocation,
                        orderItems: [],
                        supplyAgreementID: agrID,
                        isRequestDispatch: false,
                        isRequestPickup: false,
                        requestedDepartureLocationID: null,
                        tagsCSV: null
                    };

                    if (this.useFastProcessing) {
                        order.isRequestDispatch = true;
                        order.isRequestPickup = true;
                        order.requestedDepartureLocationID = this.locationID;
                        order.isBuyerCourier = false;
                    }
                }

                if (isNew) {
                    var repeatLastOrder = false;
                    var standingOrderID = null;
                    
                    if (bladeData != null && bladeData.data != null) {
                        if (bladeData.data.standingOrderID != null) {
                            standingOrderID = bladeData.data.standingOrderID;
                        }
                        else if (bladeData.data.repeatLast === true) {
                            repeatLastOrder = true;
                        }
                    }

                    if (repeatLastOrder) {
                        //find last order
                        var lastOrderRes = await this.$BlitzIt.store.getAll('customer-orders', { agreementID: agrID, takeAmount: 1, includeDetails: true });
                        if (this.isLengthyArray(lastOrderRes)) {
                            var lastOrder = lastOrderRes[0];
                            lastOrder.orderItems.forEach(o => {
                                if (o.productID != null && policy.orderItems.some(z => z.productID == o.productID)) {
                                    order.orderItems.push(this.copyDeep(o));
                                }
                            });

                            order.locationID = lastOrder.locationID;
                            order.location = lastOrder.location;
                            order.isBuyerCourier = lastOrder.isBuyerCourier;
                            order.tagsCSV = lastOrder.tagsCSV;
                        }
                        else {
                            var lastInvRes = await this.$BlitzIt.store.getAll('customer-invoices', { agreementID: agrID, sortBy: 'IssuedOn', takeAmount: 1, includeDetails: true });
                            if (this.isLengthyArray(lastInvRes)) {
                                var lastInvoice = lastInvRes[0];
                                lastInvoice.lineItems.forEach(l => {
                                    var policyItem = policy.orderItems.find(z => z.productID == l.itemID);
                                    if (policyItem != null) {
                                        order.orderItems.push({
                                            id: null,
                                            rowVersion: null,
                                            lineItemCode: null,
                                            description: l.description,
                                            productID: l.itemID,
                                            quantity: l.onOrder,
                                            unitPrice: l.unitPrice,
                                            discount: 0,
                                            sortNumber: l.sortNumber,
                                            tags: l.tags,
                                            taxType: l.taxType,
                                            isPlaceholder: false,
                                            placeholderProductID: null
                                        })
                                    }
                                })
                            }
                        }
                    }
                    else if (standingOrderID != null) {
                        //copy standing order
                        var standingOrder = await this.$BlitzIt.store.get('standing-customer-orders', standingOrderID);
                        if (standingOrder != null) {
                            order.locationID = standingOrder.locationID;
                            order.location = standingOrder.location;
                            order.isBuyerCourier = standingOrder.isBuyerCourier;
                            order.tagsCSV = standingOrder.tagsCSV;

                            standingOrder.orderItems.forEach(o => {
                                var policyItem = policy.orderItems.find(z => z.productID == o.productID);
                                if (policyItem != null) {
                                    order.orderItems.push({
                                        id: null,
                                        rowVersion: null,
                                        lineItemCode: null,
                                        description: o.product.productName,
                                        productID: o.productID,
                                        quantity: o.quantity,
                                        unitPrice: 0,
                                        discount: 0,
                                        sortNumber: policyItem.product.sortNumber,
                                        tags: policyItem.product.tags,
                                        taxType: policyItem.product.taxType,
                                        isPlaceholder: false,
                                        placeholderProductID: null
                                    })
                                }
                            });
                        }
                    }

                    if (bladeData.data != null && bladeData.data.dueDate != null) {
                        order.dueDate = bladeData.data.dueDate;
                    }
                }

                if (policy.orderingArrangement === 'Slotted') {
                    for (var i = 0; i < policy.orderSlots.length; i++) {
                        var slot = policy.orderSlots[i];
                        if (!slot.purchaseOrderID) {
                            this.dueDateOptions.unshift(slot.deliverBy);
                        }
                    }
                    if (!isNew && order.dueDate) {
                        this.dueDateOptions.unshift(order.dueDate);
                    }

                    this.dueDateOptions.sort();
                }
                else if (policy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }

                this.currentPolicy = policy;
                this.isRestrictedOrdering = true;

                if (this.useFastProcessing) {
                    this.isRestrictedOrdering = false;
                    order.dueDate = this.$BlitzIt.auth.createUTC();
                }


                return order;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async orderPulled(order) {
            if (order != null && this.isLengthyArray(order.orderItems)) {
                order.orderItems = order.orderItems.orderBy('sortNumber');
            }

            this.currentOrder = order;

            return order;
        },
        async orderSaved(item) {
            if (this.useFastProcessing) {
                this.loadingMsg = 'Processing';
                this.$forceUpdate();
                var isFound = false;
                
                while (!isFound) {
                    await this.twiddleThumbs(1500);
                    try {
                        var res = await this.$BlitzIt.store.get('stock-consignment', item.id, null, true);
                        isFound = res != null;
                    }
                    catch {
                        isFound = false;
                    }
                }

                //navigate to stock consignment once found
                this.$router.push({ name: 'picking-hub', params: { consignmentID: item.id, mode: 'instant' } });
                //this.$router.push({ name: 'stock-consignment', params: { id: item.id, mode: 'instant'} });
            }
        },
        async processPurchaseOrder(po) {
            this.loadingMsg = 'Consigning';

            var data = { id: po.id, isProcessed: true, rowVersion: po.rowVersion }
            try {
                await this.$BlitzIt.api.patch('fulfiller-orders', data);
                po.isDispatched = true;
                this.changeToggle = !this.changeToggle;
            } 
            finally {
                this.loadingMsg = null;
            }
        },
        async pullForInvoice(item, id) {
            var uri = this.companyLogoURL(item.sellerID);
            var logoImgData = await getImageData(uri, false);

            var dropoff = {
                performedOn: item.dueDate,
                orderItems: item.orderItems,
                id: id
            };
            
            var res = await this.$BlitzIt.api.post('customer-orders', dropoff, null, '/ProposedInvoice/ProposedInvoice');

            var e = {
                ...res.data.data,
                logoImgData: logoImgData
            };
            
            return e;
        },
        toggleRestrictedOrdering(order, data) {
            this.isRestrictedOrdering = !this.isRestrictedOrdering;

            if (this.isRestrictedOrdering) {
                if (this.currentPolicy.orderingArrangement === 'Slotted') {
                    for (var i = 0; i < this.currentPolicy.orderSlots.length; i++) {
                        var slot = this.currentPolicy.orderSlots[i];
                        if (!slot.purchaseOrderID) {
                            this.dueDateOptions.unshift(slot.deliverBy);
                        }
                    }
                    if (!data.isNew && order.dueDate) {
                        this.dueDateOptions.unshift(order.dueDate);
                    }

                    this.dueDateOptions.sort();
                }
                else if (this.currentPolicy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, this.currentPolicy.orderLeadHours);

                    if (!this.currentPolicy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!this.currentPolicy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
            }
            else {
                this.dateRules = null;
                this.dateFrom = null;
                this.dueDateOptions = [];
            }
        },
        cOrder(order) {
            if (this.currentPolicy != null && order != null) {
                console.log('recalculating');
                this.updateOrder(recalculateOrder(order, this.currentPolicy), false);
            }
        },
        // removeLine(lineItem) {
        //     var ind = this.currentOrder.orderItems.findIndex(x => x.id === lineItem.id);
        //     if (ind >= 0) {
        //         this.currentOrder.orderItems.splice(ind, 1);
        //         this.currentOrder.subTotal = subAmount(this.currentOrder.orderItems);
        //         this.currentOrder.taxTotal = taxAmount(this.currentOrder.orderItems);
        //         this.currentOrder.amountTotal = this.currentOrder.subTotal + this.currentOrder.taxTotal;
        //     }
        // },
        toggleDeliveryFee(order) {
            order.tagsCSV = this.toggleCSV(order.tagsCSV, 'IgnoreDeliveryFee');
            this.cOrder(order);
        },
        updateOrder(order, doSave) {
            this.currentOrder.orderItems = order.orderItems;
            this.currentOrder.taxTotal = order.taxTotal;
            this.currentOrder.subTotal = order.subTotal;
            this.currentOrder.amountTotal = order.amountTotal;
            
            if (doSave) {
                this.saveToggle = !this.saveToggle;
            }
        }
     }
}
</script>